@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        .ttl_01 {
            display: inline-flex;
        }

        .ttl_02 {
            display: inline-flex;
            transform: rotate(-7deg);
            transform-origin: right;

            @media(max-width: $md6+px) {
                font-size: 32px;
            }
        }
    }

    .cardsWrap {
        margin-top: 40px;
        gap: 20px;
        display: grid;
        grid-template-rows: repeat(2, 1fr);

        @media(max-width: $md5+px) {
            display: flex;
            flex-direction: column;
        }

        .cards {
            display: grid;
            gap: 20px;

            @media(max-width: $md4+px) {
                grid-template-columns: repeat(2, 1fr) !important;
            }

            @media(max-width: $md5+px) {
                display: flex;
                flex-direction: column;
            }

            &_01 {
                grid-template-columns: 700px 1fr;

                @media(max-width: $md3+px) {
                    grid-template-columns: calc(60% - 10px) calc(40% - 10px);
                }

                .card {

                    &:nth-child(1) {
                        overflow: hidden;
                        background-image: url('../../../assets/img/HomePage/WhyChoose/image_01.webp');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;

                        .subtitle {
                            color: #fff;
                        }
                    }

                    &:nth-child(2) {
                        border: 1px solid #FFF;

                        .subtitle {
                            color: $blue;
                        }

                        .image {
                            width: 175px;
                            top: 40px;
                            right: -92px;

                            @media(max-width: $md3+px) {
                                width: 120px;
                                right: -50px;
                            }
                        }
                    }
                }
            }

            &_02 {
                grid-template-columns: 1fr 700px;

                @media(max-width: $md3+px) {
                    grid-template-columns: calc(40% - 10px) calc(60% - 10px);
                }

                .card {

                    &:nth-child(1) {
                        background: #2B2B2B;

                        .subtitle {
                            color: $blue;
                        }

                        .image {
                            width: 216px;
                            top: 29px;
                            left: -121px;

                            @media(max-width: $md3+px) {
                                width: 140px;
                                top: 50px;
                                left: -55px;
                            }
                        }
                    }

                    &:nth-child(2) {
                        background: $blue;

                        .subtitle {
                            color: #000;
                        }

                        .text {
                            color: rgba(0, 0, 0, 0.70);
                        }

                        .image {
                            right: 0;
                            top: 0;
                            height: 100%;
                        }
                    }
                }
            }

            .card {
                position: relative;
                border-radius: 20px;
                padding: 40px 40px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 20px;
                min-height: 260px;

                @media(max-width: $md4+px) {
                    padding: 40px 30px 25px;
                }

                @media(max-width: $md5+px) {
                    min-height: 224px;
                }

                @media(max-width: $md6+px) {
                    padding: 30px 20px 25px;
                    min-height: 200px;
                }

                .subtitle,
                .text {
                    position: relative;
                    z-index: 5;
                }

                .subtitle {}

                .text {
                    max-width: 503px;
                }

                .image {
                    position: absolute;
                    z-index: 2;

                    @media(max-width: $md4+px) {
                        display: none;
                    }
                }
            }
        }
    }
}