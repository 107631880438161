@import '../../../styles/var';

.container {
    @media(max-width: $md4+px) {
        margin-top: 110px;
    }

    @media(max-width: $md6+px) {
        margin-top: 70px;
    }
}

.wrap {
    position: relative;

    .card {
        position: relative;
        border-bottom: 1px solid #515151;
        padding: 20px 40px;
        display: grid;
        grid-template-columns: 70px 1fr auto;
        align-items: center;
        gap: 100px;
        transition: all .2s;

        @media(max-width: $md4+px) {
            gap: 40px;
            padding: 20px 20px;
        }

        @media(max-width: $md5+px) {
            grid-template-columns: 1fr auto;
            gap: 20px;
        }

        @media(max-width: $md6+px) {
            padding: 20px 0;
        }

        @media(min-width: $md3+px) {
            &:hover {
                background: #2B2B2B;
                .image {
                    opacity: 1;
                }
            }
        }

        &:nth-last-child(1) {
            border: none;
        }

        &:nth-child(1) {
            .image {
                width: 303px;
                right: 280px;
            }
        }

        &:nth-child(2) {
            .image {
                width: 298px;
                right: 80px;
            }
        }

        &:nth-child(3) {
            .image {
                width: 250px;
                right: 380px;
            }
        }

        &:nth-child(4) {
            .image {
                width: 194px;
                right: 190px;
            }
        }

        .num {
            position: relative;
            z-index: 5;
            color: $blue;

            @media(max-width: $md5+px) {
                grid-column: 1/1;
                grid-row: 1/1;
            }

            @media(max-width: $md6+px) {
                font-size: 32px;
            }
        }

        .content {
            position: relative;
            z-index: 5;

            @media(max-width: $md5+px) {
                grid-column: 1/3;
                grid-row: 2/2;
            }

            .title {}

            .text {
                display: inline-flex;
                border-radius: 4px;
                border: 1px solid #515151;
                color: #515151;
                font-family: $roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                text-transform: lowercase;
                padding: 4px 12px;
                margin-top: 14px;

                @media(max-width: $md6+px) {
                    margin-top: 6px;
                    font-size: 10px;
                }
            }
        }

        .btn {
            position: relative;
            z-index: 5;
            border-radius: 70px;
            border: 1px solid #515151;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 88px;
            height: 56px;
            transition: all .2s;

            @media(max-width: $md4+px) {
                width: 70px;
                height: 48px;
            }

            @media(max-width: $md5+px) {
                grid-column: 2/2;
                grid-row: 1/1;
            }

            @media(max-width: $md6+px) {
                width: 56px;
                height: 36px;
            }

            &:hover {
                background: $blue;

                .arrow path {
                    stroke: #515151;
                }
            }

            .arrow {
                width: 24px;

                @media(max-width: $md6+px) {
                    width: 20px;
                }

                path {
                    transition: all .2s;
                }
            }
        }

        .image {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            transition: opacity 0.3s;
            opacity: 0;

            @media(max-width: $md3+px) {
                display: none;
            }
        }
    }
}