@import '../../../styles/var';

.wrap {
    position: relative;
    z-index: 5;

    @media (max-width: $md4+px) {
        margin-top: 140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 120px;
    }

    .card {
        border-bottom: 1px solid #515151;
        transition: all .2s;
        padding: 35px 40px;

        @media(max-width: $md4+px) {
            padding: 25px 30px;
        }

        @media(max-width: $md6+px) {
            padding: 20px 18px;
        }

        &:nth-last-child(1) {
            border-bottom: none;
        }

        &.active {
            background: #2A2A2A;

            .content {
                display: block;
            }

            .button {
                &::after {
                    transform: translateX(-50%) translateY(-50%) !important;
                }
            }
        }

        .head {
            display: grid;
            grid-template-columns: 120px 1fr auto;
            align-items: center;
            gap: 20px;

            @media(max-width: $md4+px) {
                grid-template-columns: 60px 1fr auto;
            }

            @media(max-width: $md5+px) {
                grid-template-columns: 1fr auto;
            }

            @media(max-width: $md6+px) {
                gap: 10px;
            }

            .num {
                color: $blue;
                font-family: $mansalva;
                font-size: 40px;
                font-weight: 400;
                line-height: 120%;
                text-transform: uppercase;

                @media(max-width: $md4+px) {
                    font-size: 32px;
                }

                @media(max-width: $md5+px) {
                    display: none;
                }
            }

            .subtitle {
                @media(max-width: $md4+px) {
                    font-size: 20px;
                }

                @media(max-width: $md6+px) {
                    font-size: 16px;
                }
            }

            .button {
                cursor: pointer;
                position: relative;
                border-radius: 70px;
                border: 1px solid #515151;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 88px;
                height: 56px;
                transition: all .2s;

                @media(max-width: $md4+px) {
                    width: 70px;
                    height: 48px;
                }

                @media(max-width: $md6+px) {
                    width: 56px;
                    height: 36px;
                }

                &:hover {
                    background: #515151;
                }

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 22px;
                    height: 3px;
                    background: $blue;
                    border-radius: 4px;

                    @media(max-width: $md4+px) {
                        width: 20px;
                    }

                    @media(max-width: $md6+px) {
                        width: 14px;
                        height: 2px;
                    }
                }

                &::after {
                    transition: all .2s;
                    transform: translateX(-50%) translateY(-50%) rotate(90deg);
                }
            }
        }

        .content {
            display: none;
            margin-top: 10px;
            margin-left: 140px;
            max-width: 730px;

            @media(max-width: $md4+px) {
                margin-left: 80px;
            }

            @media(max-width: $md5+px) {
                margin-left: 0;
            }
        }
    }
}