@import '../../../styles/var';

.wrap {
    position: relative;

    .buttons {
        position: relative;
        z-index: 5;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 20px;

        @media(max-width: $md4+px) {
            gap: 15px;
        }

        @media(max-width: 900px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        @media(max-width: $md6+px) {
            gap: 10px;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }

        .button {
            display: grid;
            grid-template-columns: 1fr 24px;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            border-radius: 70px;
            border: 1px solid #515151;
            transition: all .2s;
            color: #fff;
            min-height: 56px;
            padding: 5px 32px;
            cursor: pointer;
            line-height: 125%;

            @media(max-width: $md3+px) {
                padding: 5px 24px;
            }

            @media(max-width: $md4+px) {
                min-height: 50px;
            }

            @media(max-width: $md6+px) {
                min-height: 46px;
            }

            * {
                transition: all .2s;
            }

            .arrow {
                width: 24px;
                height: 24px;
            }

            &.active {
                background: #2A2A2A;
                border-color: #2A2A2A;
                color: $blue;

                .arrow {
                    transform: rotate(90deg);

                    rect {
                        fill: #DE4D86;
                    }

                    path {
                        stroke: #000;
                    }
                }
            }
        }
    }

    .tabWrap {
        margin-top: 100px;

        @media(max-width: $md3+px) {
            margin-top: 80px;
        }

        @media(max-width: $md4+px) {
            margin-top: 60px;
        }

        @media(max-width: $md6+px) {
            margin-top: 50px;
        }

        .tab {
            position: relative;

            &_01 {

                .image {
                    width: 525px;

                    @media(max-width: $md3+px) {
                        width: 360px;
                        bottom: -40px;
                    }

                    @media(max-width: $md4+px) {
                        max-width: 400px;
                    }
                }
            }

            &_02 {

                .image {
                    width: 604px;

                    @media(max-width: $md3+px) {
                        width: 440px;
                        bottom: 0;
                    }

                    @media(max-width: $md4+px) {
                        max-width: 460px;
                    }
                }
            }

            &_04 {

                .image {
                    width: 588px;

                    @media(max-width: $md3+px) {
                        width: 430px;
                        bottom: -30px;
                    }

                    @media(max-width: $md4+px) {
                        max-width: 460px;
                    }
                }
            }

            .title {
                margin-bottom: 40px;

                @media(max-width: $md5+px) {
                    br {
                        display: none;
                    }
                }

                span {
                    color: $pink;
                    font-family: $mansalva;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%;
                    text-transform: uppercase;
                    padding-right: 5px;

                    @media(max-width: $md4+px) {
                        font-size: 52px;
                    }

                    @media(max-width: $md6+px) {
                        font-size: 40px;
                    }
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 15px;
                max-width: 503px;

                @media(max-width: $md3+px) {
                    max-width: 460px;
                }

                @media(max-width: $md4+px) {
                    max-width: 100%;
                }
            }

            .desc {
                margin-top: 25px;
                max-width: 540px;

                @media(max-width: $md3+px) {
                    max-width: 420px;
                }

                @media(max-width: $md4+px) {
                    max-width: 100%;
                }

                @media(max-width: $md6+px) {
                    margin-top: 20px;
                }
            }

            .btn {
                margin-top: 40px;
            }

            .title,
            .text,
            .desc,
            .btn,
            .cards {
                position: relative;
                z-index: 5;
            }

            .image {
                position: absolute;
                z-index: 2;
                right: 0;
                top: -60px;

                @media(max-width: $md3+px) {
                    top: unset;
                }

                @media(max-width: $md4+px) {
                    bottom: unset !important;
                    position: relative;
                    display: flex;
                    margin: 50px auto 0;
                    width: 100% !important;
                }

                @media(max-width: $md6+px) {
                    margin-top: 40px;
                }
            }

            .cards {

                .card {
                    border-bottom: 1px solid #515151;
                    transition: all .2s;
                    padding: 35px 40px;

                    @media(max-width: $md4+px) {
                        padding: 25px 30px;
                    }

                    @media(max-width: $md6+px) {
                        padding: 20px 18px;
                    }

                    &:nth-last-child(1) {
                        border-bottom: none;
                    }

                    &.active {
                        background: #2A2A2A;

                        .content {
                            display: block;
                        }

                        .button {
                            &::after {
                                transform: translateX(-50%) translateY(-50%) !important;
                            }
                        }
                    }

                    .head {
                        display: grid;
                        grid-template-columns: 120px 1fr auto;
                        align-items: center;
                        gap: 20px;

                        @media(max-width: $md4+px) {
                            grid-template-columns: 60px 1fr auto;
                        }

                        @media(max-width: $md5+px) {
                            grid-template-columns: 1fr auto;
                        }

                        .num {
                            color: $blue;
                            font-family: $mansalva;
                            font-size: 40px;
                            font-weight: 400;
                            line-height: 120%;
                            text-transform: uppercase;

                            @media(max-width: $md4+px) {
                                font-size: 32px;
                            }

                            @media(max-width: $md5+px) {
                                display: none;
                            }
                        }

                        .subtitle {}

                        .button {
                            cursor: pointer;
                            position: relative;
                            border-radius: 70px;
                            border: 1px solid #515151;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 88px;
                            height: 56px;
                            transition: all .2s;

                            @media(max-width: $md4+px) {
                                width: 70px;
                                height: 48px;
                            }

                            @media(max-width: $md6+px) {
                                width: 56px;
                                height: 36px;
                            }

                            &:hover {
                                background: #515151;
                            }

                            &::before,
                            &::after {
                                content: '';
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translateX(-50%) translateY(-50%);
                                width: 22px;
                                height: 3px;
                                background: $blue;
                                border-radius: 4px;

                                @media(max-width: $md4+px) {
                                    width: 20px;
                                }

                                @media(max-width: $md6+px) {
                                    width: 14px;
                                    height: 2px;
                                }
                            }

                            &::after {
                                transition: all .2s;
                                transform: translateX(-50%) translateY(-50%) rotate(90deg);
                            }
                        }
                    }

                    .content {
                        display: none;
                        margin-top: 10px;
                        margin-left: 140px;
                        max-width: 730px;

                        @media(max-width: $md4+px) {
                            margin-left: 80px;
                        }

                        @media(max-width: $md5+px) {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}