@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 200px;

    @media(max-width: $md4+px) {
        margin-top: 160px;
    }

    @media(max-width: $md6+px) {
        margin-top: 140px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

            .ttl_01 {
                position: relative;
                z-index: 4;
                transform: rotate(-7deg);
                left: -14px;
                display: inline-flex;

                @media(max-width: $md4+px) {
                    left: -10px;
                }

                @media(max-width: $md6+px) {
                    left: -5px;
                }
            }

            .ttl_02 {
                position: relative;
                z-index: 5;
                color: #fff;
                margin: 5px 0 0 70px;

                @media(max-width: $md4+px) {
                    margin: 5px 0 0 60px;
                }

                @media(max-width: $md6+px) {
                    margin: 5px 0 0 40px;
                }
            }

            .arrow {
                position: absolute;
                z-index: 3;
                left: 240px;
                top: -100px;
                width: 157px;

                @media(max-width: $md4+px) {
                    width: 120px;
                    top: -74px;
                    left: 210px;
                }

                @media(max-width: $md6+px) {
                    width: 100px;
                    left: 135px;
                    top: -65px;
                }
            }
        }

        .text {
            margin: 40px 0;
            max-width: 580px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }

            @media(max-width: $md6+px) {
                margin-bottom: 20px;
            }
        }

        .desc {
            max-width: 500px;
        }

        .btn {
            margin-top: 60px;

            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: -24px;
        right: -20px;
        width: 573px;

        @media(max-width: $md3+px) {
            width: 400px;
            right: -20px;
        }

        @media(max-width: $md4+px) {
            max-width: 450px;
            width: 100%;
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            margin: 50px auto 0;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
    }
}