$roboto: "Roboto";
$manrope: "Manrope";
$mansalva: "Mansalva";

$minwidth: 375px;
$mw: 1620;
$md1: $mw + 30;
$md2: 1420;
$md3: 1220;
$md4: 992;
$md5: 768;
$md6: 480;

$pink: #DE4D86;
$blue: #75C5FF;
