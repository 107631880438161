@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 200px;

    @media(max-width: $md4+px) {
        margin-top: 160px;
    }

    @media(max-width: $md6+px) {
        margin-top: 140px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

            .ttl_01 {
                position: relative;
                z-index: 4;
                transform: rotate(-7deg);
                left: -14px;
                display: inline-flex;

                @media(max-width: $md4+px) {
                    left: -10px;
                }

                @media(max-width: $md6+px) {
                    left: -5px;
                }
            }

            .ttl_02 {
                position: relative;
                z-index: 5;
                max-width: 670px;
                color: #fff;
                margin: 5px 0 0 70px;
                
                @media (max-width: $md3+px) {
                    max-width: 550px;
                }

                @media(max-width: $md4+px) {
                    margin: 5px 0 0 60px;
                }

                @media(max-width: $md6+px) {
                    margin: 5px 0 0 40px;
                }
            }

            .arrow {
                position: absolute;
                z-index: 3;
                left: 240px;
                top: -100px;
                width: 157px;

                @media(max-width: $md4+px) {
                    width: 120px;
                    top: -74px;
                    left: 210px;
                }

                @media(max-width: $md6+px) {
                    width: 100px;
                    left: 135px;
                    top: -65px;
                }
            }
        }

        .text {
            margin-top: 40px;
            max-width: 580px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }

            @media(max-width: $md6+px) {
                gap: 15px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        top: -30px;
        right: -250px;
        width: 839px;

        @media(max-width: $md1+px) {
            width: 750px;
            right: -170px;
            top: -10px;
        }

        @media(max-width: $md3+px) {
            width: 430px;
            right: -20px;
            top: unset;
            bottom: -40px;
        }

        @media(max-width: $md4+px) {
            max-width: 600px;
            width: 100%;
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            margin: 50px auto 0;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
    }
}