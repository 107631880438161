@import './var';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mansalva&display=swap');

.font-128 {
    color: $pink;
    font-family: $mansalva;
    font-size: 128px;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 100px;
    }

    @media(max-width: $md6+px) {
        font-size: 64px;
    }
}

.font-64 {
    color: $pink;
    font-family: $mansalva;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 52px;
    }

    @media(max-width: $md6+px) {
        font-size: 40px;
    }
}

.font-58 {
    color: $pink;
    font-family: $mansalva;
    font-size: 58px;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 48px;
    }

    @media(max-width: $md6+px) {
        font-size: 32px;
    }
}

.font-48 {
    color: #FFF;
    font-family: $manrope;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    
    @media(max-width: $md4+px) {
        font-size: 40px;
    }

    @media(max-width: $md6+px) {
        font-size: 32px;
    }
}

.font-36 {
    color: $blue;
    font-family: $manrope;
    font-size: 36px;
    font-weight: 400;
    line-height: 120%;

    @media(max-width: $md4+px) {
        font-size: 30px;
    }

    @media(max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-24-roboto {
    color: $blue;
    font-family: $roboto;
    font-size: 24px;
    font-style: italic;
    font-weight: 300;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 22px;
    }

    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-24 {
    color: $blue;
    font-family: $manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 120%;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-20 {
    color: rgba(255, 255, 255, 0.70);
    font-family: $roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-16 {
    font-family: $roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}